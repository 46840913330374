export const menu = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Modules",
    path: "/modules",
  },
  {
    name: "Magazine",
    path: "/magazine",
  },
  {
    name: "About us",
    path: "/about-us",
  },
  {
    name:'Connect',
    path:'/human-resources'
  },
  {
    name: "Write Us",
    path: "/contact",
  },
];
