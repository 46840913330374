// extracted by mini-css-extract-plugin
export var chevronArrow = "footer-module--chevronArrow--aAIAJ";
export var container = "footer-module--container--2YP9M";
export var description = "footer-module--description--ILUIW";
export var footer = "footer-module--footer--+2N7a";
export var hide = "footer-module--hide--LaiQu";
export var hiringLink = "footer-module--hiringLink--kOpQT";
export var links = "footer-module--links--p7h0r";
export var logo = "footer-module--logo--5Y5nE";
export var lower = "footer-module--lower--DoVym";
export var meet_us = "footer-module--meet_us--LCWUm";
export var others = "footer-module--others--9tbvs";
export var secondLink = "footer-module--secondLink--6aZug";
export var show = "footer-module--show--x3PGS";
export var subttitle = "footer-module--subttitle--TADDl";
export var upper = "footer-module--upper--je-21";