import React, { useEffect, useState } from "react";
import "../../../styles/global.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import * as styles from "./header.module.scss";
import { menu } from "../../../constants/menu";
import { Link } from "gatsby";
import Img10 from '../../../images/10x.png';
import HeaderLogo from '../../../svg/masterwizr-logo-header.svg';

const Index = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  let className = null; 

  useEffect(() =>{
    let pathname =window.location.pathname; 
    if(pathname === '/sharing' || pathname === '/services' || pathname ==='/presentationportal' ){
      setActiveMenu(3);
      return;
    }
    else if(pathname === '/hiring'){
      setActiveMenu(4)
      return;
    }
    setActiveMenu(null);
  },[])


  return (
    <div className={styles.headerSection}>
      <div
      className={styles.containerHeader}
      style={mobileMenu ? { height: "100%" } : null}
    >
      <div className={styles.header}>
        <Link to='/'>
        <div className={styles.logo}>
          <HeaderLogo className={styles.headerLogo} />
        </div>
        </Link>
        {!mobileMenu ? (
          <FontAwesomeIcon
            onClick={() => {
              setMobileMenu(true);
            }}
            style={{ fontSize: 23, color: "EE780D" }}
            icon={faBars}
            size={25}
          />
        ) : (
          <FontAwesomeIcon
            onClick={() => {
              setMobileMenu(false);
            }}
            style={{ fontSize: 23, color: "EE780D" }}
            icon={faTimes}
            size={25}
          />
        )}
      </div>
      <>
        <div
          onClick={() => {
            setMobileMenu(false);
          }}
          onKeyDown ={() => {
            setMobileMenu(false);
          }}
          
          className={styles.overlay}
          style={mobileMenu ? { display: "block" } : { display: "none" }}
        ></div>
        <div
          className={styles.containMenu}
          style={!mobileMenu ? { left: "-150%" } : { left: 0 }}
        >
          <div className={styles.mobileMenu}>
            <ul>
              {menu.map((res, index) => {
                return (
                  <div>
                    <li  key={res.name}>
                      <Link
                        to={res.path}
                        className={index === activeMenu && 'active'}
                        activeClassName="active"
                        activeStyle={{ color: "#434343" }}
                      >
                        {res.name}
                      </Link>
                    </li>
                    {index === 3 && (
                      <ul className={styles.submenu}>
                        <Link
                          to="/sharing"
                          activeClassName="active"
                          activeStyle={{ color: "#434343", fontWeight: "bold" }}
                          id='sharing'
                        >
                          Sharing Platform
                        </Link>
                        <Link
                          to="/services"
                          activeClassName="active"
                          activeStyle={{ color: "#434343" , fontWeight : "bold" }}
                        >
                          Services
                        </Link>
                        <Link
                          to="/presentationportal"
                          activeClassName="active"
                          activeStyle={{ color: "#434343" , fontWeight: "bold" }}
                        >
                          Presentation Portal
                        </Link>
                      </ul>
                    )}
                     {index === 4 && (
                      <ul className={styles.submenu}>
                        <Link
                          to="/hiring"
                          activeClassName="active"
                          activeStyle={{ color: "#434343", fontWeight: "bold" }}
                          onClick={() => window.location.replace("/hiring")}
                        >
                          Hiring
                        </Link>
                      </ul>
                      
                    )}
                  </div>
                );
              })}
              
            <Link
            onClick={() => {
              setMobileMenu(false);
            }}
            onKeyDown ={() => {
              setMobileMenu(false);
            }}
             style={!mobileMenu ? { display:'none' } : { display:'block'}} to="/10x-why">
            <div className={styles.ex}>
              <img src={Img10} alt="10x" width={50}
              height={34}
              />
            </div>
          </Link>
            </ul>
          </div>
        </div>
      </>
      {!mobileMenu && (
        <div className={styles.buttons}>
          <Link to="/10x-why">
            <div className={styles.ex}>
              <img src={Img10} alt="10x" width={50}
              height={34}
              />
            </div>
          </Link>
          <Link to='/get-started'>
          <div className={styles.getStarted}>
            <p>Get started</p>
          </div>
          </Link>
        </div>
      )}
    </div>
    </div>
    
  );
};

export default Index;
